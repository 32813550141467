<template>
  <div class="actions_caontainer flex items-center py-3 flex-wrap">
    <!--  TODO HIDE EXPENSE BLOCK -->
    <!-- <div class="action_element flex justify-start px-4 py-4 mr-10 mb-8">
      <div class="img_container flex  justify-start">
        <svg-icon
          name="ic-expense"
          class="icon-width px-1 pr-3"
          original
        />
      </div>
      <div class=" mt-5">
        <span class="text-xl action_name font-bold">{{ getAllRealPostCost }} €</span><br>
        <span class="action-subtitle">{{ $t('expense') }}</span>
      </div>
    </div> -->
    <div class="action_element flex justify-start px-4 py-4  mr-10  mb-8">
      <div class="img_container flex  justify-start">
        <svg-icon
          name="ic-see"
          class=" icon-width px-1 pr-3"
          original
        />
      </div>
      <div class=" mt-5">
        <span class="text-xl action_name font-bold">{{ Math.round(viewers.length * logRema(1)) }}</span><br>
        <span class="action-subtitle">{{ $t('nbrSee') }}</span>
      </div>
    </div>
    <div class="action_element flex justify-start px-4 py-4  mb-8 mr-10">
      <div class="img_container flex  justify-start">
        <svg-icon
          name="ic-clics"
          class=" icon-width px-1 pr-3"
          original
        />
      </div>
      <div class=" mt-5">
        <span class="text-xl action_name font-bold">{{ Math.round(clickers.length * logRema(1)) }}</span><br>
        <span class="action-subtitle">{{ $t('nbrClick') }}</span>
      </div>
    </div>
    <!--  TODO HIDE COST PER VIEW BLOCK -->
    <!-- <div class="action_element flex justify-start px-4 py-4  mr-10 mb-8">
      <div class="img_container flex  justify-start">
        <svg-icon
          name="ic-see"
          class=" icon-width px-1 pr-3"
          original
        />
      </div>
      <div class=" mt-5">
        <span class="text-xl action_name font-bold">{{ costPerM(Math.round(viewers.length * logRema(1)) , getAllRealPostCost) }}</span><br>
        <span class="action-subtitle"> {{ $t('costView') }} </span>
      </div>
    </div> -->
    <!--  TODO HIDE COST PER CLICK BLOCK -->
    <!-- <div class="action_element flex justify-start px-4 py-4 mr-10 mb-8">
      <div class="img_container flex  justify-start">
        <svg-icon
          name="ic-clics"
          class=" icon-width px-1 pr-3"
          original
        />
      </div>
      <div class=" =mt-5">
        <span class="text-xl action_name font-bold">{{ costPerM(Math.round(clickers.length * logRema(1)), getAllRealPostCost) }}</span><br>
        <span class="action-subtitle">{{ $t('costClick') }}</span>
      </div>
    </div> -->
    <div class="action_element flex justify-start px-4 py-4   mb-8">
      <div class="img_container flex  justify-start">
        <svg-icon
          name="sms_sent"
          class=" icon-width px-1 pr-3"
          original
        />
      </div>
      <div class=" mt-5">
        <span class="text-xl action_name font-bold">{{ allSmsCost }}</span><br>
        <span class="action-subtitle"> {{ $t('sendedSms') }} </span>
      </div>
    </div>
  </div>
</template>

<script>
import { firebaseDb } from '../../main'
import { firebaseRef } from '../../rema-function/firebaseRef'
import { remaDefaultData } from '../../rema-function/remaDefaultData'
export default {
  name: 'ActionBase',
  props: {
    msg: String,
    allFilteredCampaign: Array,
    allFilteredSms: Array
  },
  data () {
    return {
      allCampaign: {},
      viewers: [],
      clickers: [],
      allGettedCampaign: [],
      realClick: 0
    }
  },
  computed: {
    getAllRealPostCost () {
      let cost = 0
      for (let i = 0; i < this.filteredAllCampaign.length; i++) {
        cost += this.convertToEuro(this.filteredAllCampaign[i].postCout, this.filteredAllCampaign[i].deviseMoney)
      }
      return cost
    },
    filteredAllCampaign: function () {
      return this.allFilteredCampaign
    },
    allSmsCost () {
      let result = 0
      for (let i = 0; i < this.allFilteredSms.length; i++) {
        if (this.allFilteredSms[i].isAlreadySend) {
          result += this.allFilteredSms[i].porteeProbable
        }
      }
      return result
    }
  },
  watch: {
    'filteredAllCampaign': function (newval) {
      this.checkClickers()
      this.checkViewers()
      // this.costPerM()
    }
  },
  methods: {
    convertToEuro (cout, deviseMoney) {
      if (deviseMoney.text === '€') {
        return cout
      } else {
        return Math.round(cout / remaDefaultData.baseEuro)
      }
    },
    logRema  (value) {
      return 1.5
    },
    costPerM (views, cost) {
      if (views === 0) {
        return 0
      } else {
        if ((cost / views).toFixed(2) > 0) {
          var with2Decimals = (cost / views).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
          return with2Decimals
        } else {
          return 0
        }
      }
    },
    checkClickers () {
      let vm = this
      this.clickers = []
      for (let i = 0; i < this.allFilteredCampaign.length; i++) {
        firebaseDb.ref(firebaseRef.clickerTab).orderByKey().equalTo(this.allFilteredCampaign[i].key)
          .once('value', function (snapchot) {
            snapchot.forEach(function (data) {
              data.forEach(function (data) {
                vm.clickers.push(data.val())
              })
            })
          })
      }
    },
    checkViewers () {
      let vm = this
      this.viewers = []
      for (let i = 0; i < this.allFilteredCampaign.length; i++) {
        firebaseDb.ref(firebaseRef.viewerTab).orderByKey().equalTo(this.allFilteredCampaign[i].key)
          .once('value', function (snapchot) {
            snapchot.forEach(function (data) {
              data.forEach(function (data) {
                vm.viewers.push(data.val())
              })
            })
          })
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/style/sass/variables';
.actions_caontainer{
  .action_element{
    cursor: pointer;
    width: 25%;
    background-color: white;
    min-width: 20rem;
    height: 10rem;
  //  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.02);
    border-radius: 10px;
    .img_container{
      .menu_element_icon{
        padding-top: 1rem;
      }
    }
    .action_name{
      font-weight: bolder;
      font-size: 1.5rem;
      color: $Dark-Text-Rema;
    }
    &:hover{
      box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.03);
    }
  }
  .action-subtitle {
    font-size: 1rem;
    font-weight: 300;
    color: $Dark-Text-Rema;
  }
  .icon-width {
    width: 5rem;
    height: 5rem;
  }
}
</style>
