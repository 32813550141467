import { render, staticRenderFns } from "./actions-base.vue?vue&type=template&id=4413d660&scoped=true"
import script from "./actions-base.vue?vue&type=script&lang=js"
export * from "./actions-base.vue?vue&type=script&lang=js"
import style0 from "./actions-base.vue?vue&type=style&index=0&id=4413d660&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4413d660",
  null
  
)

export default component.exports